@import '../../../styles/customMediaQueries.css';

.bookingCountPanel {
    display: flex;
    width: fit-content;
    border-radius: 4px;
    background: #F2F2F2;
    margin-bottom: 2rem;
  
}

.countWrapper {
    display: flex;
    padding: 20px;
    align-items: center;
    display: flex;
    padding: 20px;
    align-items: center;
    font-weight: 300;
    gap: 20px;

    flex-direction: column-reverse;

    @media (--viewportMedium) {
        flex-direction: row;
    }
}

.count {
    color: var(--marketplaceColor);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
}