.fieldWrapper {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.icon {
    width: 28px;
}

.text {
    margin-left: 0.5rem;
}