@import '../../styles/customMediaQueries.css';

.keywordsSection {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
    font-size: 14px;

    margin: 0 24px 24px 24px;

    @media (--viewportMedium) {
        margin: 0 0 24px 0;
    }
}

.keywordWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 12px;
    background-color: #40590b26;
    border: 1px solid #40590b;
    width: fit-content;
    border-radius: 8px;
}